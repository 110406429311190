const ALL_CROP_CONSTRAINTS = ['none', 'aspect-ratio', 'resolution'];
const TEMP_SIZE_METADATA_WIDTH_KEY = 'tempWidth';
const TEMP_SIZE_METADATA_HEIGHT_KEY = 'tempHeight';
import FORMAT_ASSETS from './customFormats.json';
import { getImageSize } from './editorUtils';
export function caseAssetPath(path) {
    // ce-photoeditor
    return `/imgly-assets/ce-photoeditor${path}`;
}
// engine: CreativeEngine,
// pageId: number,
// newValue: { width: number; height: number }
export function setTempSizeInMetadata(
    engine,
    pageId,
    newValue = null
) {
    if (!newValue) {
        if (getValuesFromMetadata(engine, pageId) !== null) {
            engine.block.removeMetadata(pageId, TEMP_SIZE_METADATA_WIDTH_KEY);
            engine.block.removeMetadata(pageId, TEMP_SIZE_METADATA_HEIGHT_KEY);
        }
        return;
    }
    const { width, height } = newValue;
    engine.block.setMetadata(
        pageId,
        TEMP_SIZE_METADATA_WIDTH_KEY,
        width.toString()
    );
    engine.block.setMetadata(
        pageId,
        TEMP_SIZE_METADATA_HEIGHT_KEY,
        height.toString()
    );
}
function getValuesFromMetadata(engine, pageId) {
    if (
        !engine.block.hasMetadata(pageId, TEMP_SIZE_METADATA_WIDTH_KEY) ||
        !engine.block.hasMetadata(pageId, TEMP_SIZE_METADATA_HEIGHT_KEY)
    ) {
        return null;
    }
    return {
        width: parseFloat(
            engine.block.getMetadata(pageId, TEMP_SIZE_METADATA_WIDTH_KEY)
        ),
        height: parseFloat(
            engine.block.getMetadata(pageId, TEMP_SIZE_METADATA_HEIGHT_KEY)
        )
    };
}
// We use custom metadata to store the currently active crop constraints
export function setCropConstraintMetadata(
    engine,
    constraint = 'none'
) {
    const page = engine.scene.getCurrentPage();
    if (constraint === 'none') {
        engine.block.setMetadata(page, 'cropConstraint', 'none');
    } else if (constraint === 'aspect-ratio') {
        engine.block.setMetadata(page, 'cropConstraint', 'aspect-ratio');
    } else if (constraint === 'resolution') {
        engine.block.setMetadata(page, 'cropConstraint', 'resolution');
    }
}
export function getCropConstraintMetadata(
    engine
) {
    const page = engine.scene.getCurrentPage();
    if (!page || !engine.block.findAllMetadata(page).includes('cropConstraint')) {
        return 'none';
    }
    return engine.block.getMetadata(page, 'cropConstraint');
}
export function getOriginalSize(engine) {
    const page = engine.scene.getCurrentPage();
    const fill = engine.block.getFill(page);
    const sourceSet = engine.block.getSourceSet(fill, 'fill/image/sourceSet');
    const imageSource = sourceSet[0];
    return {
        width: imageSource.width,
        height: imageSource.height,
        designUnit: 'Pixel'
    };
}
export const registerPageCropPanel = function(
    cesdk,
    panelId = 'ly.img.page-crop'
) {
    cesdk.ui.registerPanel(panelId, function ({ builder, engine }) {
        const { Section, NumberInput, Library, Button } = builder;

        let pageId = engine.scene.getCurrentPage() || null;
        if (!pageId) return;

        const realSize = {
            width: engine.block.getWidth(pageId),
            height: engine.block.getHeight(pageId)
        };
        const tempSize = getValuesFromMetadata(engine, pageId) || realSize;
        const valueHasChanged =
            tempSize.width !== realSize.width || tempSize.height !== realSize.height;

        const cropConstraint = getCropConstraintMetadata(engine);
        const designUnit = engine.scene.getDesignUnit();
        const shortDesignUnit = shortenDesignUnit(designUnit);
        const currentAspectRatio = realSize.width / realSize.height;
        Section.bind(builder)('dimensions', {
            title: `Dimensions`,
            children () {
                NumberInput('width', {
                    inputLabel: `Width (${shortDesignUnit})`,
                    value: tempSize.width,
                    isDisabled: cropConstraint === 'resolution',
                    setValue: (value) => {
                        if (cropConstraint === 'aspect-ratio') {
                            setTempSizeInMetadata(engine, pageId, {
                                width: value,
                                height: value / currentAspectRatio
                            });
                        } else {
                            setTempSizeInMetadata(engine, pageId, {
                                width: value,
                                height: tempSize.height
                            });
                        }
                    }
                });

                NumberInput('height', {
                    inputLabel: `Height (${shortDesignUnit})`,
                    value: tempSize.height,
                    isDisabled: cropConstraint === 'resolution',
                    setValue: (value) => {
                        if (cropConstraint === 'aspect-ratio') {
                            setTempSizeInMetadata(engine, pageId, {
                                width: value * currentAspectRatio,
                                height: value
                            });
                        } else {
                            setTempSizeInMetadata(engine, pageId, {
                                width: tempSize.width,
                                height: value
                            });
                        }
                    }
                });

                Button('apply', {
                    isDisabled: !valueHasChanged,
                    label: 'Apply',
                    // onClick we want to change the page size and remove temp values
                    onClick: () => {
                        const originalSize = getOriginalSize(engine);
                        if (tempSize.width <= 0 || tempSize.height <= 0) {
                            return;
                        }
                        if (
                            tempSize.width > originalSize.width ||
                            tempSize.height > originalSize.height
                        ) {
                            return;
                        }
                        engine.block.setWidth(pageId, tempSize.width);
                        engine.block.setHeight(pageId, tempSize.height);
                        // reset to crop mode to cover because the cropped area is distorted. This is a workaround until the bug is fixed:
                        engine.block.setContentFillMode(pageId, 'Cover');

                        setTempSizeInMetadata(engine, pageId, null);
                    }
                });
            }
        });

        Section('size-presets', {
            title: 'Size Presets',
            children: () => {
                Library('preset-library', {
                    entries: [
                        {
                            id: 'ly.img.formats',
                            sourceIds: ['ly.img.formats'],
                            previewLength: 3,
                            gridColumns: 3,
                            gridItemHeight: 'square',
                            gridBackgroundType: 'cover',
                            cardLabel: (assetResult) => assetResult.label,
                            cardLabelPosition: () => 'below',
                            showGroupOverview: false
                        }
                    ]
                });
            }
        });
    });
};
function closeAllPanels(instance) {
    // close crop:
    instance.ui.closePanel('ly.img.page-crop');
    // exit crop mode:
    if (instance.engine.editor.getEditMode() === 'Crop') {
        instance.engine.editor.setEditMode('Transform');
    }
    // close asset library panels:
    instance.ui.closePanel('//ly.img.panel/assetLibrary');
    // close inspector panels:
    instance.ui.closePanel('//ly.img.panel/inspector/adjustments');
    instance.ui.closePanel('//ly.img.panel/inspector/filters');
}
/*
engine: CreativeEngine,
    content: ContentJSON,
    baseURL = '',
    applyAsset?: ((asset: AssetResult) => Promise<number | undefined>) | undefined
*/
const loadAssetSourceFromContentJSON =  async function(
    engine,
    content,
    baseURL = '',
    applyAsset
) {
    const { assets, id: sourceId } = content;
    engine.asset.addLocalSource(sourceId, undefined, applyAsset);
    assets.forEach((asset) => {
        if (asset.meta) {
            Object.entries(asset.meta).forEach(([key, value]) => {
                const stringValue = value.toString();
                if (stringValue.includes('{{base_url}}')) {
                    const updated = stringValue.replace('{{base_url}}', baseURL);
                    if (asset.meta) {
                        asset.meta[key] = updated;
                    }
                }
            });
        }

        if (asset.payload?.sourceSet) {
            asset.payload.sourceSet.forEach((sourceSet) => {
                sourceSet.uri = sourceSet.uri.replace('{{base_url}}', baseURL);
            });
        }

        engine.asset.addAssetToSource(sourceId, asset);
    });
}
function createApplyFormatAsset(
    instance
) {
    return async function (asset) {
        const {engine} = instance;
        const page = engine.scene.getCurrentPage();
        // Set fill mode to cover:
        engine.block.setContentFillMode(page, 'Cover');
        // Select it:
        let newDesignUnit = null;
        let newWidth = null;
        let newHeight = null;
        // reset temp size in metadata
        setTempSizeInMetadata(engine, page, null);
        if (asset.id === 'page-sizes-custom') {
            // Reset Page Size to original:
            setCropConstraintMetadata(engine, 'none');
            const originalSize = getOriginalSize(engine);
            newWidth = originalSize.width;
            newHeight = originalSize.height;
            newDesignUnit = originalSize.designUnit;
        } else if (asset.meta?.fixedResolution === 'true') {
            if (!asset.meta?.formatWidth || !asset.meta?.formatHeight) {
                console.error(
                    'Asset is missing properties meta.formatWidth or meta.formatHeight'
                );
                return;
            }
            newWidth = parseInt(asset.meta.formatWidth, 10);
            newHeight = parseInt(asset.meta.formatHeight, 10);
            newDesignUnit = asset.meta.designUnit;
            setCropConstraintMetadata(engine, 'resolution');
        } else if (asset.meta?.aspectRatio) {
            const aspectRatio = asset.meta.aspectRatio;
            const [width, height] = aspectRatio.split(':').map(Number);
            // adjust size to match aspect ratio
            const { width: originalWidth, height: originalHeight } =
                getOriginalSize(engine);
            const originalAspectRatio = originalWidth / originalHeight;
            const newAspectRatio = width / height;
            if (originalAspectRatio > newAspectRatio) {
                newWidth = originalHeight * newAspectRatio;
                newHeight = originalHeight;
            } else {
                newWidth = originalWidth;
                newHeight = originalWidth / newAspectRatio;
            }
            setCropConstraintMetadata(engine, 'aspect-ratio');
        }
        if (newDesignUnit) {
            engine.scene.setDesignUnit(newDesignUnit);
        }
        if (newWidth && newHeight) {
            engine.block.resizeContentAware([page], newWidth, newHeight);
        }
        // enter crop:
        engine.editor.setEditMode('Crop');
        return page;
    };
}

export async function setupPhotoEditingScene(
    instance,
    uri
) {
    const engine = instance.engine;
    const size = await getImageSize(uri);
    if (!size || !size.width || !size.height) {
        throw new Error('Could not get image size');
    }
    const { width, height } = size;
    // hide page title:
    engine.editor.setSettingBool('page/title/show', false);

    const scene = engine.scene.create('Free');
    engine.scene.setDesignUnit('Pixel');
    const page = engine.block.create('page');
    // Add page to scene:
    engine.block.appendChild(scene, page);
    // Set page size:
    engine.block.setWidth(page, width);
    engine.block.setHeight(page, height);
    // Create image fill"
    const fill = engine.block.createFill('image');
    // Set fill url:
    engine.block.setSourceSet(fill, 'fill/image/sourceSet', [
        { uri, width, height }
    ]);
    engine.block.setFill(page, fill);
    // Set content fill mode to cover:
    engine.block.setContentFillMode(page, 'Cover');
    // Disable changing fill of page, hides e.g also the "replace" button
    engine.block.setScopeEnabled(page, 'fill/change', false);
    engine.block.setScopeEnabled(page, 'fill/changeType', false);
    // Disable stroke of page, since it does not make sense with current wording and takes up to much space
    engine.block.setScopeEnabled(page, 'stroke/change', false);

    // only allow resizing and moving of page in crop mode
    const unsubscribeStateChange = engine.editor.onStateChanged(() => {
        const editMode = engine.editor.getEditMode();
        const cropConstraint = getCropConstraintMetadata(engine);
        if (editMode !== 'Crop') {
            // close size preset panel
            instance.ui.closePanel('ly.img.page-crop');
            engine.editor.setSettingBool(
                'ubq://page/allowResizeInteraction',
                false
            );
            return;
        }
        if (cropConstraint === 'none') {
            engine.editor.setSettingBool(
                'ubq://page/restrictResizeInteractionToFixedAspectRatio',
                false
            );
            engine.editor.setSettingBool(
                'ubq://page/allowResizeInteraction',
                true
            );
        } else if (cropConstraint === 'aspect-ratio') {
            engine.editor.setSettingBool(
                'ubq://page/restrictResizeInteractionToFixedAspectRatio',
                true
            );
            engine.editor.setSettingBool(
                'ubq://page/allowResizeInteraction',
                true
            );
        } else if (cropConstraint === 'resolution') {
            engine.editor.setSettingBool(
                'ubq://page/allowResizeInteraction',
                false
            );
            engine.editor.setSettingBool(
                'ubq://page/restrictResizeInteractionToFixedAspectRatio',
                false
            );
        }
    });

    // If nothing is selected: select page by listening to selection changes
    const unsubscribeSelectionChange = engine.block.onSelectionChanged(() => {
        const selection = engine.block.findAllSelected();
        if (selection.length === 0) {
            const page = engine.scene.getCurrentPage();
            engine.block.select(page);
        }
    });

    // Initially select the page
    engine.block.select(page);
    return () => {
        unsubscribeSelectionChange();
        unsubscribeStateChange();
    };
}
// Find out more about changing the Dock in the documentation:
// https://img.ly/docs/cesdk/ui/customization/api/dock/
export const setupImageDock = function (instance) {

    loadAssetSourceFromContentJSON(
        instance.engine,
        FORMAT_ASSETS,
        caseAssetPath(''),
        createApplyFormatAsset(instance)
    );
    // crop button, should open the crop asset lib panel but also enter crop mode
    instance.ui.registerComponent(
        'ly.img.crop.dock',
        function ({ builder }) {
            const { Button } = builder;
            const isFormatAssetLibraryOpen =
                instance.ui.isPanelOpen('ly.img.page-crop');
            Button('open-crop', {
                label: 'Crop',
                icon: ({ theme }) => {
                    return caseAssetPath(`/crop-large-${theme}.svg`);
                },
                isSelected: isFormatAssetLibraryOpen,
                onClick: async () => {
                    if (isFormatAssetLibraryOpen) {
                        instance.ui.closePanel('ly.img.page-crop');
                        instance.engine.editor.setEditMode('Transform');
                        return;
                    }
                    closeAllPanels(instance);
                    const page = instance.engine.scene.getCurrentPage();
                    instance.engine.block.select(page);
                    await new Promise((resolve) => setTimeout(resolve, 100));
                    instance.ui.openPanel('ly.img.page-crop');
                    instance.engine.editor.setEditMode('Crop');
                }
            });
        }
    );
    // create a custom button for the text asset library entry
    instance.ui.registerComponent(
        'ly.img.text.dock',
        function ({ builder }) {
            const { Button } = builder;
            const textLibraryPayload = {
                entries: ['ly.img.text'],
                title: 'libraries.ly.img.text.label'
            };
            const isTextAssetLibraryOpen = instance.ui.isPanelOpen(
                '//ly.img.panel/assetLibrary',
                { payload: textLibraryPayload }
            );
            Button('open-text', {
                label: 'Text',
                icon: '@imgly/Text',
                isSelected: isTextAssetLibraryOpen,
                onClick: () => {
                    if (isTextAssetLibraryOpen) {
                        instance.ui.closePanel('//ly.img.panel/assetLibrary');
                    } else {
                        closeAllPanels(instance);
                        instance.ui.openPanel('//ly.img.panel/assetLibrary', {
                            payload: textLibraryPayload
                        });
                    }
                }
            });
        }
    );
    // create a custom button for the vector path asset library entry
    instance.ui.registerComponent(
        'ly.img.vectorpath.dock',
        function ({ builder }) {
            const { Button } = builder;
            const vectorPathLibraryPayload = {
                entries: ['ly.img.vectorpath'],
                title: 'libraries.ly.img.vectorpath.label'
            };
            const isVectorPathAssetLibraryOpen = instance.ui.isPanelOpen(
                '//ly.img.panel/assetLibrary',
                { payload: vectorPathLibraryPayload }
            );
            Button('open-vectorpath', {
                label: 'libraries.ly.img.vectorpath.label',
                icon: '@imgly/Shapes',
                isSelected: isVectorPathAssetLibraryOpen,
                onClick: () => {
                    if (isVectorPathAssetLibraryOpen) {
                        instance.ui.closePanel('//ly.img.panel/assetLibrary');
                    } else {
                        closeAllPanels(instance);
                        instance.ui.openPanel('//ly.img.panel/assetLibrary', {
                            payload: vectorPathLibraryPayload
                        });
                    }
                }
            });
        }
    );
    // create a custom button for the sticker asset library entry
    instance.ui.registerComponent(
        'ly.img.sticker.dock',
        function ({ builder }) {
            const { Button } = builder;
            const stickerLibraryPayload = {
                entries: ['ly.img.sticker'],
                title: 'libraries.ly.img.sticker.label'
            };
            const isStickerAssetLibraryOpen = instance.ui.isPanelOpen(
                '//ly.img.panel/assetLibrary',
                { payload: stickerLibraryPayload }
            );
            Button('open-sticker', {
                label: 'Sticker',
                icon: '@imgly/Sticker',
                isSelected: isStickerAssetLibraryOpen,
                onClick: () => {
                    if (isStickerAssetLibraryOpen) {
                        instance.ui.closePanel('//ly.img.panel/assetLibrary');
                    } else {
                        closeAllPanels(instance);
                        instance.ui.openPanel('//ly.img.panel/assetLibrary', {
                            payload: stickerLibraryPayload
                        });
                    }
                }
            });
        }
    );
    // create a custom button for the apps asset library entry
    // instance.ui.registerComponent(
    //     'ly.img.apps.dock',
    //     function ({ builder: { Button } }) {
    //         const appsLibraryPayload = {
    //             entries: ['ly.img.apps'],
    //             title: 'libraries.ly.img.apps.label'
    //         };
    //         const isAppsAssetLibraryOpen = instance.ui.isPanelOpen(
    //             '//ly.img.panel/assetLibrary',
    //             { payload: appsLibraryPayload }
    //         );
    //         Button('open-apps', {
    //             label: 'Apps',
    //             icon: ({ theme }) => caseAssetPath(`/apps-sizes-large-${theme}.svg`),
    //             isSelected: isAppsAssetLibraryOpen,
    //             onClick: () => {
    //                 if (isAppsAssetLibraryOpen) {
    //                     instance.ui.closePanel('//ly.img.panel/assetLibrary');
    //                 } else {
    //                     closeAllPanels(instance);
    //                     instance.ui.openPanel('//ly.img.panel/assetLibrary', {
    //                         payload: appsLibraryPayload
    //                     });
    //                 }
    //             }
    //         });
    //     }
    // );

    instance.ui.registerComponent(
        'ly.img.adjustment.dock',
        function ({ builder: { Button } }) {
            const inspectorOpen = instance.ui.isPanelOpen(
                '//ly.img.panel/inspector/adjustments'
            );
            Button('open-adjustments', {
                label: 'Adjust',
                icon: ({ theme }) => caseAssetPath(`/adjustment-large-${theme}.svg`),
                isSelected: inspectorOpen,
                onClick: () => {
                    if (inspectorOpen) {
                        instance.ui.closePanel('//ly.img.panel/inspector/adjustments');
                    } else {
                        closeAllPanels(instance);
                        const page = instance.engine.scene.getCurrentPage();
                        instance.engine.block.select(page);
                        instance.ui.openPanel('//ly.img.panel/inspector/adjustments', {
                            floating: true
                        });
                    }
                }
            });
        }
    );
    instance.ui.registerComponent(
        'ly.img.filter.dock',
        function ({ builder }) {
            const inspectorOpen = instance.ui.isPanelOpen(
                '//ly.img.panel/inspector/filters'
            );
            const { Button } = builder;
            Button('open-filters', {
                label: 'Filter',
                icon: ({ theme }) => caseAssetPath(`/filter-large-${theme}.svg`),
                isSelected: inspectorOpen,
                onClick: () => {
                    if (inspectorOpen) {
                        instance.ui.closePanel('//ly.img.panel/inspector/filters');
                    } else {
                        closeAllPanels(instance);
                        const page = instance.engine.scene.getCurrentPage();
                        instance.engine.block.select(page);
                        instance.ui.openPanel('//ly.img.panel/inspector/filters', {
                            floating: true
                        });
                    }
                }
            });
        }
    );

    // instance.ui.addAssetLibraryEntry({
    //     id: 'ly.img.apps',
    //     sourceIds: ['ly.img.apps'],
    //     previewLength: 3,
    //     gridColumns: 3,
    //     gridItemHeight: 'auto',
    //     previewBackgroundType: 'contain',
    //     gridBackgroundType: 'cover',
    //     cardLabel: (assetResult) => assetResult.label,
    //     cardLabelPosition: () => 'below'
    // });
    instance.ui.addAssetLibraryEntry({
        id: 'ly.img.formats',
        sourceIds: ['ly.img.formats'],
        previewLength: 3,
        gridColumns: 3,
        gridItemHeight: 'auto',
        previewBackgroundType: 'contain',
        gridBackgroundType: 'cover',
        cardLabel: (assetResult) => assetResult.label,
        cardLabelPosition: () => 'below'
    });

    instance.ui.setDockOrder([
        'ly.img.spacer',
        'ly.img.crop.dock',
        // asset library
        {
            "id": "ly.img.assetLibrary.dock",
            "key": "ly.img.elements",
            "icon": "@imgly/Library",
            "label": "component.library.elements",
            "entries": [
                "ly.img.upload",
                // "ly.img.video",
                // "ly.img.audio",
                "ly.img.image",
                "ly.img.text",
                "ly.img.vectorpath",
                // "ly.img.sticker",
                "cutout-entry"
            ]
        },
        'ly.img.adjustment.dock',
        'ly.img.filter.dock',
        // 'ly.img.separator',
        // We use a custom button for the text asset library entry instead of the default one
        // This way we can ensure to close the other panels when opening the text asset library
        'ly.img.text.dock',
        // 'ly.img.vectorpath.dock',
        // 'ly.img.sticker.dock',
        'ly.img.spacer',
        // 'ly.img.apps.dock'
    ]);
}
export const setupVideoDock = function (instance) {
    instance.ui.setDockOrder(
        [
            'ly.img.spacer',
            {
                "id": "ly.img.assetLibrary.dock",
                "key": "ly.img.template",
                "icon": "@imgly/Template",
                "label": "libraries.ly.img.template.label",
                "entries": [
                    "ly.img.template"
                ]
            },
            {
                "id": "ly.img.assetLibrary.dock",
                "key": "ly.img.video.template",
                "icon": "@imgly/Template",
                "label": "libraries.ly.img.video.template.label",
                "entries": [
                    "ly.img.video.template"
                ]
            },
            {
                "id": "ly.img.separator"
            },
            {
                "id": "ly.img.assetLibrary.dock",
                "key": "ly.img.elements",
                "icon": "@imgly/Library",
                "label": "component.library.elements",
                "entries": [
                    "ly.img.upload",
                    "ly.img.video",
                    "ly.img.audio",
                    "ly.img.image",
                    "ly.img.text",
                    "ly.img.vectorpath",
                    "ly.img.sticker",
                    "cutout-entry"
                ]
            },
            {
                "id": "ly.img.assetLibrary.dock",
                "key": "ly.img.upload",
                "icon": "@imgly/Upload",
                "label": "Add Media",
                "entries": [
                    "ly.img.upload"
                ]
            },
            {
                "id": "ly.img.assetLibrary.dock",
                "key": "ly.img.video",
                "icon": "@imgly/Video",
                "label": "libraries.ly.img.video.label",
                "entries": [
                    "ly.img.video"
                ]
            },
            {
                "id": "ly.img.assetLibrary.dock",
                "key": "ly.img.audio",
                "icon": "@imgly/Audio",
                "label": "libraries.ly.img.audio.label",
                "entries": [
                    "ly.img.audio"
                ]
            },
            // {
            //     "id": "ly.img.assetLibrary.dock",
            //     "key": "ly.img.image",
            //     "icon": "@imgly/Image",
            //     "label": "libraries.ly.img.image.label",
            //     "entries": [
            //         "ly.img.image"
            //     ]
            // },
            // {
            //     "id": "ly.img.assetLibrary.dock",
            //     "key": "ly.img.text",
            //     "icon": "@imgly/Text",
            //     "label": "libraries.ly.img.text.label",
            //     "entries": [
            //         "ly.img.text"
            //     ]
            // },
            // {
            //     "id": "ly.img.assetLibrary.dock",
            //     "key": "ly.img.vectorpath",
            //     "icon": "@imgly/Shapes",
            //     "label": "libraries.ly.img.vectorpath.label",
            //     "entries": [
            //         "ly.img.vectorpath"
            //     ]
            // },
            // {
            //     "id": "ly.img.assetLibrary.dock",
            //     "key": "ly.img.sticker",
            //     "icon": "@imgly/Sticker",
            //     "label": "libraries.ly.img.sticker.label",
            //     "entries": [
            //         "ly.img.sticker"
            //     ]
            // },
            'ly.img.spacer',
        ]
    )
}
function shortenDesignUnit(designUnit) {
    // "Pixel" | "Millimeter" | "Inch"
    switch (designUnit) {
        case 'Millimeter':
            return 'mm';
        case 'Inch':
            return 'in';
        default:
            return 'px';
    }
}