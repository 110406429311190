<template>
    <div id="cesdk_container" style="height: 90vh; width: 100%">
        <div v-if="!imglyLibReady"
            style="background: rgb(204, 204, 204);display: flex;height: 100%;width: 100%;justify-content: center;align-items: center;">
            Loading</div>
    </div>
</template>


<script>
import { defaults, map } from 'lodash';
const imglyEditorURL = 'https://cdn.img.ly/packages/imgly/cesdk-js/1.40.0/cesdk.umd.js';
import { registerPageCropPanel, setupImageDock, setupVideoDock, getCropConstraintMetadata, setupPhotoEditingScene } from './EditorExtras';
// const onLocal = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
// TODO: VUE_APP_BASE_URI is not defined
// const baseURL = `${onLocal ? window.location.host : VUE_APP_BASE_URI}/imgly`;
const defaultConfig = {
    // // TODO: Figure out hosting imgly assets locally, right now its throwing an error when exporting a video
    // // TODO: Also see if netlify will build correctly after adding all the necessary assets in /public
    // core: {
    //     // Specify location of core assets, required by the engine.
    //     baseURL: `${baseURL}/core`
    // },
    // baseURL,
    ui: {
        elements: {
            navigation: {
                action: {
                    close: true,
                    back: true,
                    save: true,
                    load: false,
                    export: false
                }
            }
        },
        panels: {
            inspector: {
                show: false,
            },
        },
        pageFormats: {
            '1080p': {
                default: true,
                width: 1920,
                height: 1080,
                unit: 'Pixel',
                fixedOrientation: true
            },
        }
    }
}
export default {
    props: { config: Object },
    data() {
        return {
            imglyLibReady: false,
            editorInitialized: false,
            pendingChanges: false,
            pendingSave: false,
            editingSlideIndex: null,
            mediaType: null,
            _cesdk: null,
        }
    },
    methods: {
        async createBlankScene() {
            await this._cesdk.createDesignScene();
        },
        onUnsupportedBrowser() {
            /* This is the default window alert which will be shown in case an unsupported
             * browser tries to run CE.SDK */
            window.alert(
                'Your current browser is not supported.\nPlease use one of the following:\n\n- Mozilla Firefox 86 or newer\n- Apple Safari 14.1 or newer\n- Microsoft Edge 88 or newer\n- Google Chrome 88 or newer'
            );
        },
        onBack() {
            this.onClose();
        },
        onClose() {
            // TODO: check for unsaved changes and prompt before closing the editor
            this.$emit('close');
            this.$nextTick(() => {
                this.createBlankScene();
            });
        },
        // When user clicks a button, we check if there are any changes to blocks/scene that we need to wait for
        async onSave(scene) {
            // console.log('Performing Save');
            const activeScene = this._cesdk.engine.scene.get();
            // debugger;
            const currentPage = this._cesdk.engine.scene.getCurrentPage();
            let mimeType, options, blob, metadata = {};
            // Video exporting is handled differently than image
            if (this.mediaType === 1) {
                /* Export page as mp4 video. */
                mimeType = 'video/mp4';
                const progressCallback = (renderedFrames, encodedFrames, totalFrames) => {
                    console.log(
                        'Rendered',
                        renderedFrames,
                        'frames and encoded',
                        encodedFrames,
                        'frames out of',
                        totalFrames
                    );
                };
                // Get duration
                metadata.duration = this._cesdk.engine.block.getDuration(currentPage);
                blob = await this._cesdk.engine.block.exportVideo(
                    currentPage,
                    mimeType,
                    progressCallback,
                    {}
                );
            } else {
                mimeType = 'image/png';
                // const options = { pngCompressionLevel: 8, targetWidth: 960, targetHeight: 540 };
                // The higher the compression the more taxing...
                options = { pngCompressionLevel: 0 };

                blob = await this._cesdk.engine.block.export(activeScene, mimeType, options);
            }
            this.$emit('onSave', { scene, blob, slideIndex: this.editingSlideIndex, metadata });

            this.$nextTick(async () => {
                this.editingSlideIndex = null;
                this.createBlankScene();
            });
        },
        onLoad() {
            console.log('onLoad stub method');
            // const scene = '...'; // Fill with scene
            // return Promise.resolve(scene);
        },
        onUpload(file, onProgress) {
            console.log('onUpload stub method');
            // const newImage = {
            //     id: 'exampleImageIdentifier',
            //     meta: {
            //         uri: 'https://YOURSERVER/images/file.jpg',
            //         thumbUri: 'https://YOURSERVER/images/thumb.jpg'
            //     }
            // };
            // return Promise.resolve(newImage);
        },
        async loadScene(sceneData, slideIndex, mediaType) {
            if (typeof slideIndex === 'number' && slideIndex > -1) {
                this.editingSlideIndex = slideIndex;
            }
            this.mediaType = mediaType;
            await this._cesdk.engine.scene.loadFromString(sceneData);
        },
        async createSceneFromURL(mediaURL, slideIndex, mediaType) {
            if (typeof slideIndex === 'number' && slideIndex > -1) {
                this.editingSlideIndex = slideIndex;
            }
            // Store mediaType to reference later
            this.mediaType = mediaType;
            if (mediaType === 0) {
                await setupPhotoEditingScene(this._cesdk, mediaURL);
            }
            if (mediaType === 1) {
                await this._cesdk.engine.scene.createFromVideo(mediaURL);
            }
            if (mediaType === 2) {
                await this._cesdk.engine.scene.createFromImage(mediaURL);
            }
        },
        async init(mediaType = 0) {
            let scriptPromise;
            if (!document.getElementById('imglyEditor')) {
                console.log('CreativeEditor component embedding the imgly script');
                // DX HMR FIx: If the script isn't on the document anymore then clear out these flags too.
                this.imglyLibReady = false;
                this.editorInitialized = false;

                scriptPromise = new Promise((resolve) => {
                    const imglyLib = document.createElement('script');
                    imglyLib.src = imglyEditorURL;
                    imglyLib.id = 'imglyEditor';
                    imglyLib.onload = () => {
                        // console.log('imgly editor loaded from CDN');
                        resolve();
                        this.imglyLibReady = true;
                    }
                    document.getElementsByTagName('head')[0].appendChild(imglyLib);
                })
            } else {
                console.log('CreativeEditor component imgly script already available', this);
                if (!this.imglyLibReady) {
                    // DX HMR FIx: If the script isn't on the document anymore then clear out these flags too.
                    this.imglyLibReady = true;
                    this.editorInitialized = false;
                }
                scriptPromise = Promise.resolve();
            }
            return scriptPromise.then(() => {
                console.log('CreativeEditor component begin init');
                let initPromise;
                if (!this.editorInitialized || !this._cesdk) {
                    let ceConfig = {
                        license: process.env.VUE_APP_IMGLY_CESDK_KEY,
                        callbacks: {
                            onUnsupportedBrowser: this.onUnsupportedBrowser,
                            onBack: this.onBack,
                            onClose: this.onClose,
                            onSave: this.onSave,
                            onLoad: this.onLoad,
                            // Use local uploads: https://img.ly/docs/cesdk/ui/guides/upload-images?language=js#local-uploads
                            onUpload: 'local'
                        },
                        // mash together defaults from above and settings passed in via $props
                        ...defaults(defaultConfig, this.Config || {})
                    };
                    // once loaded CreativeEditorSDK is injected globally
                    initPromise = CreativeEditorSDK.create('#cesdk_container', ceConfig)
                    
                } else {
                    initPromise = new Promise((resolve) => {
                        resolve(this._cesdk);
                    });
                }
                return initPromise.then(async (instance) => {
                    console.log('CE editor initialized...', instance);
                    const { engine } = instance;
                    if (!this._cesdk) {
                        console.log('CE editor doing initial setup... for this._cesdk');
                        this._cesdk = instance;
                        // Remove ability to add pages.
                        instance.feature.enable('ly.img.page.add', false);
                        // Disable placeholder and preview features
                        instance.feature.enable('ly.img.placeholder', false);
                        instance.feature.enable('ly.img.preview', false);
                        instance.feature.enable('ly.img.replace', false);
                        // set basePath, so that CE doesn't try and load local assets from their CDN
                        // engine.editor.setSettingString('basePath', `${onLocal ? baseURL : '/imgly/'}`);
                        engine.editor.setSettingString('basePath', '');
                    }

                    // Do setup for different scene types
                    if (mediaType !== 1) {
                        instance.setTranslations({
                            en: {
                                'panel.ly.img.page-crop': 'Crop',
                                // setup translation for formats
                                'libraries.ly.img.formats.label': 'Size',
                                'libraries.ly.img.formats.custom.label': 'Custom',
                                'libraries.ly.img.formats.social.label': 'Social',
                                'libraries.ly.img.formats.print.label': 'Print',
                            },
                        });
                        instance.ui.openPanel('ly.img.page-crop');

                        registerPageCropPanel(instance);
                        setupImageDock(instance);
                        // Do something with the instance of CreativeEditor SDK, for example:
                        
                        // instance.addDefaultAssetSources({ baseURL });
                        instance.addDefaultAssetSources();
                        instance.addDemoAssetSources({ sceneMode: 'Design' });
                        instance.engine.editor.setSettingBool('doubleClickToCropEnabled', true);
                        // only allow resizing and moving of page in crop mode
                        const unsubscribeStateChange = engine.editor.onStateChanged(() => {
                            const editMode = engine.editor.getEditMode();
    
                            const cropConstraint = getCropConstraintMetadata(engine);
                            if (editMode !== 'Crop') {
                                // close size preset panel
                                instance.ui.closePanel('ly.img.page-crop');
                                engine.editor.setSettingBool(
                                    'ubq://page/allowResizeInteraction',
                                    false
                                );
                                return;
                            }
                            if (cropConstraint === 'none') {
                                engine.editor.setSettingBool(
                                    'ubq://page/restrictResizeInteractionToFixedAspectRatio',
                                    false
                                );
                                engine.editor.setSettingBool(
                                    'ubq://page/allowResizeInteraction',
                                    true
                                );
                            } else if (cropConstraint === 'aspect-ratio') {
                                engine.editor.setSettingBool(
                                    'ubq://page/restrictResizeInteractionToFixedAspectRatio',
                                    true
                                );
                                engine.editor.setSettingBool(
                                    'ubq://page/allowResizeInteraction',
                                    true
                                );
                            } else if (cropConstraint === 'resolution') {
                                engine.editor.setSettingBool(
                                    'ubq://page/allowResizeInteraction',
                                    false
                                );
                                engine.editor.setSettingBool(
                                    'ubq://page/restrictResizeInteractionToFixedAspectRatio',
                                    false
                                );
                            }
                        });
                    } else { 
                        instance.addDemoAssetSources({ sceneMode: 'Video' });
                        setupVideoDock(instance);
                    }

                    this.editorInitialized = true;
                });   
            })
        }
    },
    watch: {},
    beforeUnmount() {
        // console.log('destroying the imgly editor.');
        if (this._cesdk) {
            this._cesdk.dispose();
            this._cesdk = null;
        }
    },
    // mounted() {
    //     this.init();
    // }
};
</script>
